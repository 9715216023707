@tailwind base;
@tailwind components;
@tailwind utilities;

body{ 
  background: #faf2e1;
}

.rmdp-container {
  @apply w-full
}

/* nomi giorni della settimana */
.rmdp-week-day {
  @apply !text-black !text-opacity-50 uppercase !text-[12px];
}
/* nomi mesi */
.rmdp-header-values {
  @apply !text-blue !font-bold;
}
.bg-light-yellow{
  background-color: rgb(255 251 240);
}
.ep-arrow {
  @apply hidden
}
/* date selezionate */
.rmdp-range {
  @apply !bg-blue bg-opacity-20 !shadow-none !text-black;

  &.start {
    @apply !bg-blue !rounded-l-md !text-white;
  }
  &.end {
  @apply !bg-blue !rounded-r-md !text-white;
  }
}

/* data in hover */
.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  @apply !bg-blue !rounded-md;
}

.rmdp-disabled> span{
  text-decoration: line-through !important;
}

/* .rmdp-day,
.rmdp-week-day {
  @apply px-4
} */

.rmdp-container {
  @apply w-full
}

/* nomi giorni della settimana */
.rmdp-week-day {
  @apply !text-black !text-opacity-50 uppercase !text-[12px];
}
/* nomi mesi */
.rmdp-header-values {
  @apply !text-blue !font-bold;
}

.ep-arrow {
  @apply hidden
}
/* date selezionate */
.rmdp-range {
  @apply !bg-blue bg-opacity-20 !shadow-none !text-black;

  &.start {
    @apply !bg-blue !rounded-l-md !text-white;
  }
  &.end {
  @apply !bg-blue !rounded-r-md !text-white;
  }
}


.rmdp-arrow{
margin-top: 0 !important;
}
.rmdp-arrow-container{
align-items: center;
}
.rmdp-arrow-container:hover{
background-color: #254351 !important;
}
.rmdp-day-picker,
.rmdp-top-class {
  display:  block !important;
}

.mobile .rmdp-top-class {
  @apply w-screen;
}

.mobile .rmdp-container > div:nth-child(3) {
  position: fixed !important;
  bottom: 0px !important;
  top: auto !important;
  transform: none !important;
}

.mobile .rmdp-day {
  width: 100% !important;
}

.rmdp-action-buttons {
  @apply relative top-[-83px];
}

button {
  @apply !text-white text-lg !bg-blue !rounded-lg !py-3 !px-10
}

.rmdp-top-class {
  @apply bg-[#fffbf0];
}

.rmdp-calendar {
  @apply !px-3;
}

.rmdp-day-picker {
  @apply !px-0;
}


/** Style for generic calendar */
@media (min-width: 768px){
  .generic-calendar .rmdp-day-picker{
    display: flex !important;
  }
  .generic-calendar .rmdp-container > div:nth-child(3){
    position: absolute !important;
    top: unset !important;
    bottom: unset !important;
  }
  .generic-calendar .rmdp-top-class{
    width: auto !important;
  }
}


/* REMOVES ARROWS IN INPUT NUMBER FIELD*/ 
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
